<template>
  <layout>

    <h2 class="uppercase text-2xl text-center mb-6">Resumen</h2>

    <div class="w-3/4 mx-auto">
      <div class="flex mb-3">
        <div class="w-2/5 font-bold">Método de Pago:</div>
        <div class="w-3/5 uppercase">{{ order.charge.type }}</div>
      </div>

      <div class="flex mb-3">
        <div class="w-2/5 font-bold">Nombre:</div>
        <div class="w-3/5">{{ fullname }}</div>
      </div>

      <div class="flex mb-3">
        <div class="w-2/5 font-bold">Email:</div>
        <div class="w-3/5">{{ order.customer.email }}</div>
      </div>

      <div class="flex mb-3">
        <div class="w-2/5 font-bold">Monto:</div>
        <div class="w-3/5">${{ currency(order.amount) }}</div>
      </div>

      <div class="flex mb-3">
        <div class="w-2/5 font-bold">F. Expiración:</div>
        <div class="w-3/5">{{ tsFormat(order.charge.expires_at) }}</div>
      </div>
    </div>

    <div class="flex justify-center">
      <a :href="order.links.receipt_payment" target="_blank" class="uppercase text-1xl text-center mt-6">Descargar boleta</a>
    </div>

  </layout>
</template>

<script>
import Layout from '@/views/templates/basic/Layout'
import MetasMixin from '@/mixins/meta'

export default {
  name: 'Receipt',

  mixins: [ MetasMixin ],

  components: {
    Layout,
  },

  computed: {
    fullname () {
      return `${this.order.customer.firstname} ${this.order.customer.lastname}`
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
